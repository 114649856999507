<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!--<div><router-link to="/Admin/zsls/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>-->
                    <div style="width:200px;"><el-input v-model="where.keyword" placeholder="用户姓名或手机号或老师姓名" style="width:200px;"></el-input></div>
                    <div style="width:350px;"><el-date-picker format="yyyy-MM-dd HH:mm" v-model="where.times" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker></div>
                    <div style="width:90px;">
                        <el-select v-model="where.shenhtg" placeholder="选择截图审核类型">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="待审核" value="3"></el-option>
                            <el-option label="已通过" value="1"></el-option>
                            <el-option label="未通过" value="2"></el-option>
                        </el-select>
                    </div>
                    <div style="width:140px;">
                        <el-select v-model="where.shifbs" placeholder="选择是否补色">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="新客" value="新客"></el-option>
                            <el-option label="补色" value="补色"></el-option>
                        </el-select>
                    </div>
                    <div style="width:150px;">
                        <el-select v-model="where.xiaofly" placeholder="请选择顾客来源" @change="selectChange()">
                            <el-option label="选择顾客来源" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['xiaofly']" :label="v.mingc" :key="k" :value="v.id"></el-option>
                        </el-select>
                    </div>
                    <div style="width:150px;">
                        <el-select v-model="where.zhiffs" placeholder="请选择支付方式" @change="selectChange()">
                            <el-option label="选择支付方式" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['zhiffs']" :label="v.mingc" :key="k" :value="v.id"></el-option>
                        </el-select>
                    </div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="id" label="#"  width="50px"></el-table-column>
                    <el-table-column prop="xingm" label="姓名" width="60px"></el-table-column>
                    <el-table-column prop="shoujh" label="手机号码" width="95px"></el-table-column>
                    <el-table-column prop="nic" label="微信昵称" width="70px"></el-table-column>
                    <!--<el-table-column label="性别" width="50px">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.F_TI_XINGB==1">男</el-tag>
                            <el-tag type="danger" v-else-if="scope.row.F_TI_XINGB==2">女</el-tag>
                        </template>
                    </el-table-column>-->
                    <!--<el-table-column prop="F_VR_SHOUJIHM" label="手机号码" width="100px"></el-table-column>
                    <el-table-column label="头像" width="70px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_TOUX||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                            </dl>
                        </template>
                    </el-table-column>-->


                    <el-table-column prop="xingb" label="性别" width="50px"></el-table-column>
                    <el-table-column prop="menddz" label="门店地址" width="100px"></el-table-column>
                    <el-table-column prop="xiaoftc" label="消费套餐"></el-table-column>
                    <el-table-column prop="shijfkje" label="实际付款金额" width="100px"></el-table-column>
                    <el-table-column prop="fuwls" label="服务老师"></el-table-column>

                    <el-table-column prop="peis" label="配色" ></el-table-column>
                    <el-table-column prop="shifbs" label="是否补色" width="70px"></el-table-column>
                    <el-table-column prop="xiaofly" label="顾客来源"></el-table-column>
                    <el-table-column prop="zhiffs" label="支付方式"></el-table-column>
                    <el-table-column prop="beiz" label="备注" width="100px"></el-table-column>


                    <!--<el-table-column label="付款凭证" align="center" width="70px">
                        <template slot-scope="scope">
                            <span >
                                <el-popover placement="left" trigger="click" width="600">
                                    <img :src="scope.row.fukpz" width="100%" />
                                    <img
                                            slot="reference"
                                            :src="scope.row.fukpz"
                                            :alt="scope.row.fukpz"
                                            style="max-height: 70px;max-width: 70px; padding: 5px"
                                    />
                                </el-popover>
                            </span>
                        </template>
                    </el-table-column>-->


                    <el-table-column label="付款凭证" align="center" width="185px">
                        <template slot-scope="scope">
                            <span v-for="(item,index) in scope.row.fukpz" :key="index">
                                <el-popover placement="left" trigger="click" width="600">
                                    <img :src="item" width="100%" />
                                    <img
                                            slot="reference"
                                            :src="item"
                                            :alt="item"
                                            style="max-height: 70px;max-width: 70px; padding: 5px"
                                    />
                                </el-popover>
                            </span>
                        </template>
                    </el-table-column>




                    <el-table-column prop="bussj" label="补色时间"></el-table-column>


                    <el-table-column label="审核状态" width="100px">
                        <template slot-scope="scope">
                            <el-tag type="danger" v-if="scope.row.shenhtg==2">审核拒绝</el-tag>
                            <el-tag type="warning" v-else-if="scope.row.shenhtg==3">待审核</el-tag>
                            <el-tag type="success " v-else-if="scope.row.shenhtg==1">审核通过</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column prop="adv_start" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.createtime<=0"> - </div>
                            <div v-else>{{scope.row.createtime|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" fixed="right" width="200px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit"  @click="verify_click(scope.row.id)">审核</el-button>
                            <el-button icon="el-icon-edit" @click="$router.push({name:'xianxdd_form',params:{id:scope.row.id}})">编辑</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>

                    <!-- <el-table-column label="操作" fixed="right" width="120px">
                         <template slot-scope="scope">
                             <el-button icon="el-icon-edit" @click="$router.push({name:'zsls_form',params:{id:scope.row.F_IN_ID}})">编辑</el-button>
                             <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column> -->
                    <div slot="append">
                        <div class='sum_footer xiaoji' ref='sum_xiaoji'>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit center' >总金额：{{zongje}}</div>
                            <div class='sum_footer_unit center' >订单数：{{dingds}}</div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                            <div class='sum_footer_unit'></div>
                        </div>
                    </div>
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
              keyword:'',
              shenhtg:'',
              times:[],
          },
          info:[],
          zongje:0,
          dingds:0
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.id);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            //this.where.page = this.current_page;
            let where = {};
            where.page = this.current_page;
            where.params = this.where;
            this.$get(this.$api.getXianxddList,where).then(res=>{
                /*this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.current_page = res.data.current_page;
                this.list = res.data.data;*/



                this.page_size = res.data.info.per_page;
                this.total_data = res.data.info.total;
                this.current_page = res.data.info.current_page;
                this.list = res.data;
                this.info = res.data.info.data;
                this.zongje = res.data.zongje;
                this.dingds = res.data.dingds;

            })

        },
        // 删除处理
        del:function(id){
            if(this.$isEmpty(id)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delXianxdd,{id:id}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
        verify_click:function(id){
            this.$confirm('此操作将控制是否通过审核, 是否继续?', '提示', {
                confirmButtonText: '通过',
                cancelButtonText: '拒绝',
                type: 'info'
            }).then(() => {
                this.$post(this.$api.xianxddVerifyChange,{id:id,shenhtg:1}).then(()=>{
                    this.$message.success('成功审核');
                    this.get_adv_list();
                });

            }).catch(() => {
                this.$post(this.$api.xianxddVerifyChange,{id:id,shenhtg:2}).then(()=>{
                    this.$message.info('已拒绝');
                    this.get_adv_list();
                });

            });
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
    .table_dl_dd_all{
        height: 50px;
        overflow: hidden;
    }
    .sum_footer{
        display:flex;
        display:-webkit-flex;
        line-height:50px;
        color:#606266;
    }
    .sum_footer_unit{
        flex-grow:1;
        -webkit-flex-grow:1;
        text-indent:10px;
        font-size:14px;
        font-weight:bold;
    }
    .sum_footer_unit.center{
        text-align:center;
    }
    .sum_footer.xiaoji{
        border-bottom:1px solid #EBEEF5;
    }

    .admin_main_block_left div {
        float: left;
        margin-right: 2px;
    }

    .el-range-editor--small .el-range-input {
        font-size: 13px;
        width: 120px;
    }
</style>